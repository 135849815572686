<template>
  <div>
    <div v-if="type == 'tooltip'">
      <div v-html="getHtml" v-tooltip.right="getTooltip"></div>
    </div>
    <div v-else>
      <img v-bind:src="source" v-bind:width="width" v-bind:height="height" />
    </div>
  </div>
</template>
<script>
export default {
  name: "TableImage",
  props: {
    type: {
      default: "tooltip",
    },
    source: {
      default: () => null,
    },
    width: {
      default: () => "",
    },
    height: {
      default: () => "",
    },
    hidden: {
      default: () => "",
    },
  },
  computed: {
    getHtml() {
      let result = "";

      if (this.hidden && this.source) {
        result = '<i class="fas fa-camera"></i>';
      } else {
        result = '<i class="fas fa-camera text-muted"></i>';
      }

      return result;
    },
    getTooltip() {
      let result;

      if (this.source) {
        result = `<img src="${this.source}" `;

        if (this.width) {
          result += `width="${this.width}" `;
        }

        if (this.height) {
          result += `height="${this.height}" `;
        }

        result += " class='img-fluid pt-2 pb-2' />";
      }

      return result;
    },
  },
};
</script>
